
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';
import IconFont from '@/components/icons/IconFont.vue';

import CustomersTemplatesModule from '@/store/customers/templates';
import CustomersModule from '@/store/customers';
import CustomerEntityModule from '@/store/customers/entity';
import Loading from '@/components/Loading.vue';

@Component({
  components: {
    TitleReturn,
    FormBlock,
    IconFont,
    Loading,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId;
  },
})
export default class TemplatesAdd extends Form {
  customerId = '';
  isLoading = true;

  get titlePage(): string {
    return CustomersTemplatesModule.pageSettings.titleAdd;
  }

  get clientName(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers_vacancies', params: { customerId: this.customerId } });
  }

  async mounted(): Promise<void> {
    await CustomerEntityModule.initEdit(this.customerId);

    this.isLoading = false;
  }
}
